<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">进件列表</div>
      <el-form :inline="true" size="mini">
        <el-form-item label="当面付服务商:" placeholder="请选择">
          <el-select v-model="p.appId" @click.native="getF2f()">
            <el-option label="全部" :value="null"></el-option>
            <el-option v-for="item in zftlist" :key="item.appid" :label="item.mchName" :value="item.appid" />
          </el-select>
        </el-form-item>
        <el-form-item label="支付宝账号:">
          <el-input v-model="p.account" placeholder="支付宝账号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="this.sa_admin.role == 2" icon="el-icon-search" @click="
              p.page = 1;
              f5();
            ">查询
          </el-button>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新
          </el-button>
        </el-form-item>

        <br />
      </el-form>

      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <el-table :data="dataList" size="mini" :header-cell-style="{ 'text-align': 'center' }"
                      :cell-style="{ 'text-align': 'center' }">
              <el-table-column label="id" prop="id" width="50px" />
              <el-table-column label="支付宝账号" prop="account" width="200px" />
              <el-table-column label="直付通服务商" prop="configMchName" width="200px" />
              <el-table-column label="法人姓名" prop="contactName" width="100px" />
              <el-table-column label="提交状态" width="100px">
                <template slot-scope="s">
                  <el-link icon="el-icon-loading" v-if="s.row.batchStatus == 'init'">初始化
                  </el-link>
                  <el-link v-if="s.row.batchStatus == 'submit'">已提交</el-link>
                  <el-link style="color: red;" icon="el-icon-error" v-if="s.row.batchStatus == 'timeout'">超时</el-link>
                  <el-link style="color: darkgoldenrod" v-if="s.row.batchStatus == 'cancel'">取消</el-link>
                </template>
              </el-table-column>
              <el-table-column label="服务费率" prop="rate" width="100px" />
              <el-table-column label="二级商户确认状态" width="200px">
                <template slot-scope="s">
                  <el-link style="color: #805322" icon="el-icon-s-cooperation"
                           v-if="s.row.orderStatus == 'MERCHANT_INFO_HOLD'">暂存
                  </el-link>
                  <el-link style="color: #2d8cf0" icon="el-icon-loading"
                           v-if="s.row.orderStatus == 'MERCHANT_CONFIRM'">待商户确认
                  </el-link>
                  <el-link style="color: red;" icon="el-icon-error"
                           v-if="s.row.orderStatus == 'MERCHANT_APPLY_ORDER_CANCELED'">审核失败
                  </el-link>
                  <el-link style="color: red;" icon="el-icon-error"
                           v-if="s.row.orderStatus == 'MERCHANT_CONFIRM_TIME_OUT'">商户超时未确认
                  </el-link>
                  <el-link icon="el-icon-loading" v-if="s.row.orderStatus == 'MERCHANT_AUDITING'">审核中</el-link>
                  <el-link style="color: green;" icon="el-icon-success"
                           v-if="s.row.orderStatus == 'MERCHANT_CONFIRM_SUCCESS'">签约完成
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column label="失败原因" prop="rejectReason" min-width="150px">
                <template slot-scope="s">
                  <b style="color: red; margin-left: 10px">{{ s.row.rejectReason }}</b>
                </template>
              </el-table-column>
              <el-table-column label="创建时间" width="100px">
                <template slot-scope="s">
                  <b style="margin-left: 10px">{{
                      sa.forDate2(s.row.createdTime, '')
                    }}</b>
                </template>
              </el-table-column>
              <el-table-column prop="address" label="操作" width="255px" fixed="right">
                <template slot-scope="s">
                  <el-button class="c-btn"
                             v-if="s.row.orderStatus == 'MERCHANT_CONFIRM' || s.row.orderStatus == 'MERCHANT_AUDITING'"
                             type="success" icon="el-icon-refresh" @click="update(s.row)">更新状态
                  </el-button>
                  <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="page-box">
              <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page"
                             :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]"
                             @current-change="f5(true)" @size-change="f5(true)">
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      p: {
        account: null,
        appId: null,
        page: 1,
        limit: 10,
      },
      dataCount: 0,
      dataList: [],
      zftlist: null,
    }
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
        type: 'post',
      }
      if (this.sa_admin.role == 3) {
        this.p.mchId = this.sa_admin.mchId
      }
      this.sa.ajax(
        '/v1/alipay/f2f/merchant/list',
        this.p,
        function (res) {
          this.dataList = res.data.records // 数据
          this.dataCount = res.data.total // 分页
        }.bind(this),
        defaultCfg,
      )
    },
    update (data) {
      var defaultCfg = {
        type: 'get',
      }
      var p = {
        id: data.id,
      }
      if (this.sa_admin.role == 3) {
        this.p.mchId = this.sa_admin.mchId
      }
      this.sa.ajax(
        '/v1/alipay/f2f/merchant/query',
        p,
        function () {
          this.f5()
        }.bind(this),
        defaultCfg,
      )
    },
    remarksFormat (row) {
      if (row.mch == '' || null) {
        return '未获取到'
      } else {
        return row.mch
      }
    },
    getF2f: function () {
      var defaultCfg = {
        sleep: 50,
        isBody: true, //是否是请求体请求
        msg: null,
      }
      var p = {
        page: 0,
        type: [4],
        payType: [0],
        limit: 30,
      }
      this.sa.ajax(
        '/v1/alipay/config/query', p,
        function (res) {
          this.zftlist = res.data.records // 数据
        }.bind(this),
        defaultCfg,
      )
    },
    // 删除
    del: function (data) {
      var defaultCfg = {
        type: 'delete', //是否是请求体请求
      }
      this.sa.confirm(
        '不会作废商户,只删除申请记录,是否删除?',
        function () {
          this.sa.ajax(
            '/v1/alipay/f2f/merchant/delete/' + data.id,
            function () {
              this.f5()
              this.sa.ok('删除成功')
            }.bind(this),
            defaultCfg,
          )
        }.bind(this),
      )
    },
  },
  created: function () {
    this.f5()
    this.getF2f()
  },
}
</script>

<style>
</style>
